<template>
  <div>

    <b-row>
      <b-col md="12">
        <b-form-group
          :label="$t('Código Postal')"
        >
          <b-form-input
            v-model="form_data.sw070s39"
            v-mask="tokenMaskCodigoPostal"
            autocomplete="off"
          />
        </b-form-group>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { mask } from 'vue-the-mask'
import { mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import { showMsgRequest } from '@core-custom/mixins/geral'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
  },
  directives: {
    Ripple,
    mask,
  },
  mixins: [showMsgRequest],
  data() {
    return {
      tokenMaskCodigoPostal: {
        mask: 'XXXXX',
        tokens: {
          X: {
            pattern: /[0-9]/,
            transform(v) {
              return v.toLocaleUpperCase()
            },
          },
        },
      },
    }
  },
  computed: {
    ...mapGetters('crm_visitas_form', ['form_data']),
  },
  methods: {
  },
}
</script>
